// 基础功能
import store from '@/store'
import http from '@/utils/http'
import qs from 'qs'

let preApi = '';
if (store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 视频回放
export function getPlayUrl(params) {
  return http({
    url: `${preApi}/playurl/getPlayUrl`,
    method: "post",
    data: params
  })
}

// 单位树形列表查询
export function getTreeOfUserDepHasUser(params) {
  return http({
    url: `${preApi}/userdep/getTreeOfUserDepHasUser`,
    method: "post",
    data: params
  })
}

// 维保单位列表查询
export function getUserdepListByCondition(params) {
  return http({
    url: `${preApi}/userdep/getUserdepListByCondition`,
    method: "post",
    data: params
  })
}
// 维保单位根据父级id查询
export function getChildTreeListByParentId(params) {
  let key = qs.stringify(params);
  // if(store.state.cache.deptMap[key]) {
  //   return new Promise((resolve, reject) => {
  //     resolve(store.state.cache.deptMap[key])
  //   })
  // }else {
  return http({
    url: `${preApi}/userdep/getChildTreeListByParentId`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/addDept', {
      key: key,
      data: res
    })
    return res;
  })
  // }
}
// 维保单位父级单位查询
export function getParentTreeByid(params) {
  return http({
    url: `${preApi}/userdep/getParentTreeByid`,
    method: "post",
    data: params
  })
}
// 新增维保单位
export function addUserdep(params) {
  return http({
    url: `${preApi}/userdep/addUserdep`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}
// 修改维保单位
export function modifyUserdep(params) {
  return http({
    url: `${preApi}/userdep/modifyUserdep`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}
// 维保单位删除
export function deleteUserdep(params) {
  return http({
    url: `${preApi}/userdep/deleteUserdep`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}

// 电梯档案-列表
export function getLiftinfoListByCondition(params) {
  return http({
    url: `${preApi}/lift/getLiftinfoListByCondition`,
    method: "post",
    data: params
  })
}
// 电梯档案-详情
export function getLiftinfoById(params) {
  return http({
    url: `${preApi}/lift/getLiftinfoById`,
    method: "post",
    data: params
  })
}
// 电梯档案-新增
export function addLiftinfo(params) {
  return http({
    url: `${preApi}/lift/addLiftinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-修改
export function modifyLiftinfo(params) {
  return http({
    url: `${preApi}/lift/modifyLiftinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-删除
export function deleteLiftinfo(params) {
  return http({
    url: `${preApi}/lift/deleteLiftinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-查询审批记录
export function getApproveListByCondition(params) {
  return http({
    url: `${preApi}/lift/getApproveListByCondition`,
    method: "post",
    data: params
  })
}
// 电梯档案-查询待审批电梯列表
export function findLiftinfoListByCheckCondition(params) {
  return http({
    url: `${preApi}/lift/findLiftinfoListByCheckCondition`,
    method: "post",
    data: params
  })
}
// 电梯档案-审批
export function approveLift(params) {
  return http({
    url: `${preApi}/lift/approveLift`,
    method: "post",
    data: params
  })
}
// 电梯档案-检测
export function addExamineinfo(params) {
  return http({
    url: `${preApi}/lift/addExamineinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-查询检测记录
export function findExamineListByCondition(params) {
  return http({
    url: `${preApi}/lift/findExamineListByCondition`,
    method: "post",
    data: params
  })
}

// 电梯属性-列表
export function getLiftListByCondition(params) {
  let key = qs.stringify(params);
  if (store.state.cache.liftpropertyMap[key]) {
    return new Promise((resolve, reject) => {
      resolve(store.state.cache.liftpropertyMap[key])
    })
  } else {
    return http({
      url: `${preApi}/lift/getLiftListByCondition`,
      method: "post",
      data: params
    }).then(res => {
      store.commit('cache/addLiftproperty', {
        key: key,
        data: res
      })
      return res;
    })
  }
}
// 电梯属性-新增
export function addLiftproperty(params) {
  return http({
    url: `${preApi}/lift/addLiftproperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetLiftproperty')
    return res;
  })
}
// 电梯属性-修改
export function modifyLiftproperty(params) {
  return http({
    url: `${preApi}/lift/modifyLiftproperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetLiftproperty')
    return res;
  })
}
// 电梯属性-删除
export function deleteLiftproperty(params) {
  return http({
    url: `${preApi}/lift/deleteLiftproperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetLiftproperty')
    return res;
  })
}

// 电梯属性-删除
export function getPluginParam(params) {
  return http({
    url: `${preApi}/playurl/getPluginParam`,
    method: "post",
    data: params
  })
}

// 7.1.37.	添加区域表
export function addArea(params) {
  return http({
    url: `${preApi}/area/addArea`,
    method: "post",
    data: params
  })
}
// 7.1.38.	更新区域表
export function modifyArea(params) {
  return http({
    url: `${preApi}/area/modifyArea`,
    method: "post",
    data: params
  })
}
// 7.1.39.	删除区域表
export function deleteArea(params) {
  return http({
    url: `${preApi}/area/deleteArea`,
    method: "post",
    data: params
  })
}
// 添加设备条目
export function addDevice(params) {
  return http({
    url: `${preApi}/device/addDevice`,
    method: "post",
    data: params
  })
}
// 更新设备条目
export function modifyDevice(params) {
  return http({
    url: `${preApi}/device/modifyDevice`,
    method: "post",
    data: params
  })
}
// 删除设备条目
export function deleteDevice(params) {
  return http({
    url: `${preApi}/device/deleteDevice`,
    method: "post",
    data: params
  })
}
// 查询设备条目
export function getSecondaryDeviceList(params) {
  return http({
    url: `${preApi}/device/getSecondaryDeviceList`,
    method: "post",
    data: params
  })
}
// 查询设备子条目
export function getThirdDeviceList(params) {
  return http({
    url: `${preApi}/device/getThirdDeviceList`,
    method: "post",
    data: params
  })
}
// 监控点属性-新增
export function addProperty(params) {
  return http({
    url: `${preApi}/property/addProperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetProperty')
    return res;
  })
}
// 监控点属性-修改
export function modifyProperty(params) {
  return http({
    url: `${preApi}/property/modifyProperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetProperty')
    return res;
  })
}
// 监控点属性-删除
export function deleteProperty(params) {
  return http({
    url: `${preApi}/property/deleteProperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetProperty')
    return res;
  })
}
// 监控点属性-查询
export function getPropertyByCondition(params) {
  return http({
    url: `${preApi}/property/getPropertyByCondition`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetProperty')
    return res;
  })
}
//单位查询
export function getPlatUserdepMapListByCondition(params) {
  return http({
    url: `${preApi}/userdep/getPlatUserdepMapListByCondition`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}

//单位查询
export function getUserdepMapListByCondition(params) {
  return http({
    url: `${preApi}/userdep/getUserdepMapListByCondition`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}
// 维保单位根据父级id查询
export function getMonitorPointChildTreeListByParentId(params) {
  let key = qs.stringify(params);
  return http({
    url: `${preApi}/userdep/getMonitorPointChildTreeListByParentId`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/addDept', {
      key: key,
      data: res
    })
    return res;
  })
}
export function getMonitorPointCompanyChildTreeListByParentId(params) {
  let key = qs.stringify(params);
  return http({
    url: `${preApi}/userdep/getMonitorPointCompanyChildTreeListByParentId`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/addDept', {
      key: key,
      data: res
    })
    return res;
  })
}
//联动参数
export function addEventLinkage(params) {
  return http({
    url: `${preApi}/device/addEventLinkage`,
    method: "post",
    data: params
  })
}
export function updateEventLinkage(params) {
  return http({
    url: `${preApi}/device/updateEventLinkage`,
    method: "post",
    data: params
  })
}
export function getEventLinkageListByCondition(params) {
  return http({
    url: `${preApi}/device/getEventLinkageListByCondition`,
    method: "post",
    data: params
  })
}

//导出报警/异常历史excel
export function exportEventRecordExcel(params) {
  return http({
    url: `${preApi}/event/exportEventRecordExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//导出报警/异常历史excel
export function exportEventRecordReportExcel(params) {
  return http({
    url: `${preApi}/event/exportEventRecordReportExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//导出报警/异常历史查阅excel
export function exportCheckEventRecordExcel(params) {
  return http({
    url: `${preApi}/event/exportCheckEventRecordExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}


// 新增场景模板
export function addSceneTemplate(params) {
  return http({
    url: `${preApi}/scene/addSceneTemplate`,
    method: "post",
    data: params
  })
}
// 修改场景模板
export function modifySceneTemplate(params) {
  return http({
    url: `${preApi}/scene/modifySceneTemplate`,
    method: "post",
    data: params
  })
}
// 删除场景模板
export function deleteSceneTemplate(params) {
  return http({
    url: `${preApi}/scene/deleteSceneTemplate`,
    method: "post",
    data: params
  })
}
// 查询场景模板
export function getSceneTemplateListByCondition(params) {
  return http({
    url: `${preApi}/scene/getSceneTemplateListByCondition`,
    method: "post",
    data: params
  })
}
// 查询项目场景模板
export function getMonitorPointSceneListByCondition(params) {
  return http({
    url: `${preApi}/scene/getMonitorPointSceneListByCondition`,
    method: "post",
    data: params
  })
}
// 查询项目场景模板
export function getMonitorPointSceneList(params) {
  return http({
    url: `${preApi}/scene/getMonitorPointSceneList`,
    method: "post",
    data: params
  })
}
// 根据ID查询场景模板信息
export function getSceneTemplateById(params) {
  return http({
    url: `${preApi}/scene/getSceneTemplateById`,
    method: "post",
    data: params
  })
}