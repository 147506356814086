/*
 * @Author: zhaoxin
 * @Date: 2020-11-03 21:54:41
 * @Description: 引用该mixin还需要做以下操作
 * @Description: 1. watch监听 deptCascaderSelected ,处理form表单的userdepid
 * @Description: 2. 数据反显调用this.initDeptOptionsByUserdepid(userdepid)方法
 * @Description: 3. 清空表单时重置this.deptCascaderSelected = []
 * @Description: 4. 模板使用下面代码
 * <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="deptCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择区域" :allow-clear="false"></a-cascader>
 */
import { getItemFromArrayByKey, getTreeDeepString } from 'U'
import { getChildTreeListByParentId, getParentTreeByid } from 'A/jcgn.js'
import {getMonitorPointChildTreeListByParentId, getMonitorPointCompanyChildTreeListByParentId} from "A/jcgn";

export default {
  data() {
    return {
      deptOptions: [],
      deptOptionsAll: [],
      deptCascaderSelected: [],
    }
  },
  created() {
    this.initDeptOptions();
  },
  methods: {
    initDeptOptions(parentid='') {
      let params = {
        parentid,
        deptype: this.initDeptType
      };
      getChildTreeListByParentId(params).then(res => {
        if(res && res.returncode == '0') {
          this.deptOptions = res.item.map(item => {
            item.userdepid = ''+item.userdepid;
            item.isLeaf = item.childsize === 0;
            return item;
          });
        }
      })
    },
    initDeptOptionsAll(parentid='') {
      let params = {
        parentid,
        deptype: this.initDeptType,
        isall: 'true'
      };
      getChildTreeListByParentId(params).then(res => {
        if(res && res.returncode == '0') {
          this.deptOptionsAll = res.item.map(item => {
            item.userdepid = ''+item.userdepid;
            item.isLeaf = item.childsize === 0;
            return item;
          });
        }
      })
    },
    deptOnLoadChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildDept(targetOption.userdepid);
    },
    deptOnLoadChildrenAll(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildDept(targetOption.userdepid, true);
    },
    getChildDept(parentid, isall=false) {
      let deptOptions = isall ? this.deptOptionsAll : this.deptOptions;
      let targetOption = getItemFromArrayByKey(deptOptions, 'userdepid', parentid);
      let params = {
        parentid,
        isall:isall?'true':'false',
      }
      getChildTreeListByParentId(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.userdepid = ''+item.userdepid;
            item.isLeaf = item.childsize === 0;
            return item;
          });
          if(isall) {
            this.deptOptionsAll = [...this.deptOptionsAll];
          }else {
            this.deptOptions = [...this.deptOptions]
          }
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },


    deptOnLoadMonitorPointChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getMonitorPointChildDept(targetOption.userdepid);
    },
    deptOnLoadMonitorPointChildrenAll(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getMonitorPointChildDept(targetOption.userdepid, true);
    },
    getMonitorPointChildDept(parentid, isall=false) {
      let deptOptions = isall ? this.deptOptionsAll : this.deptOptions;
      let targetOption = getItemFromArrayByKey(deptOptions, 'userdepid', parentid);
      let params = {
        parentid,
        isall:isall?'true':'false',
      }
      getMonitorPointChildTreeListByParentId(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.userdepid = ''+item.userdepid;
            item.isLeaf = item.childsize === 0;
            return item;
          });
          if(isall) {
            this.deptOptionsAll = [...this.deptOptionsAll];
          }else {
            this.deptOptions = [...this.deptOptions]
          }
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    deptOnLoadMonitorPointCompanyChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getMonitorPointCompanyChildDept(targetOption.userdepid);
    },
    deptOnLoadMonitorPointCompanyChildrenAll(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getMonitorPointCompanyChildDept(targetOption.userdepid, true);
    },
    getMonitorPointCompanyChildDept(parentid, isall=false) {
      let deptOptions = isall ? this.deptOptionsAll : this.deptOptions;
      let targetOption = getItemFromArrayByKey(deptOptions, 'userdepid', parentid);
      let params = {
        parentid,
        isall:isall?'true':'false',
      }
      getMonitorPointCompanyChildTreeListByParentId(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.userdepid = ''+item.userdepid;
            item.isLeaf = item.childsize === 0;
            return item;
          });
          if(isall) {
            this.deptOptionsAll = [...this.deptOptionsAll];
          }else {
            this.deptOptions = [...this.deptOptions]
          }
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },


    initDeptOptionsByUserdepid(userdepid, selectedName='deptCascaderSelected', isall) {
      let params = {
        userdepid
      }
      getParentTreeByid(params).then(res => {
        if(res && res.returncode == '0') {
          let userdepidTreeStr = getTreeDeepString(res.item, '', 'userdepid', 'childUserdep');
          let deepDeptList = userdepidTreeStr.split('|');
          if(deepDeptList.length > 1) {
            this.initDeptListTree(deepDeptList, 0, selectedName, isall);
          }else {
            this[selectedName] = deepDeptList;
          }
        }
      })
    },
    initDeptListTree(deepDeptList, index, selectedName, isall) {
      if(index < deepDeptList.length-1) {
        let parentid = deepDeptList[index];
        let deptOptions = isall ? this.deptOptionsAll : this.deptOptions;
        let targetOption = getItemFromArrayByKey(deptOptions, 'userdepid', parentid);
        let params = {
          parentid,
          isall:isall?'true':'false',
        }
        getChildTreeListByParentId(params).then(res => {
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.userdepid = ''+item.userdepid;
              item.isLeaf = item.childsize === 0;
              return item;
            });
            // this.deptOptions = [...this.deptOptions]
            this.initDeptListTree(deepDeptList, index+1, selectedName, isall)
          }
        })
      }else {
        this[selectedName] = deepDeptList;
      }
    },
    getDeptInfoFormDeptOptionsByDeptid(deptid, isall) {
      let deptOptions = isall ? this.deptOptionsAll : this.deptOptions;
      return getItemFromArrayByKey(deptOptions, 'userdepid', deptid);
    },


    loadChildrenDept(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getUserDeptChildDept(targetOption.userdepid);
    },
    getUserDeptChildDept(parentid) {
      let targetOption = getItemFromArrayByKey(this.deptOptions, 'userdepid', parentid);
      let params = {
        parentid
      }
      getChildTreeListByParentId(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.userdepid = item.userdepid.toString();
            item.isLeaf = item.childsize === 0;
            return item;
          });
          this.deptOptions = [...this.deptOptions]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
  }
}