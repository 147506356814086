<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="报警/故障内容" prop="content" style="width: 25%">
              <a-input v-model="queryParams.content"></a-input>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            <a-form-model-item label="部件类型" prop="unittype" style="width: 25%">
              <a-input v-model="queryParams.unittype"></a-input>
            </a-form-model-item>
              <a-form-model-item label="编号/位置" prop="location" style="width: 25%">
                <a-input v-model="queryParams.location"></a-input>
              </a-form-model-item>
              <a-form-model-item label="安装位置" prop="address" style="width: 25%">
                <a-input v-model="queryParams.address"></a-input>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="starttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
<!--                <a-menu-item :key="'detail-'+record.firepanelid">详情</a-menu-item>-->
                <a-menu-item :key="'delete-'+record.firepanelid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal :visible="detailVisible" title="报警历史详情" @cancel="detailVisible=false" :footer="null" :width="1200">
      <a-form-model ref="queryForm" :model="detailParams" layout="inline" class="query-form">
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
          <a-input placeholder="系统自动生成" style="width: 200px;" v-model="detailParams.eventnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="报警时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="编号/位置" prop="location" style="width: 30%">
          <a-textarea v-model="detailParams.location" placeholder="" cols="24" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="到场人员" prop="arriveman" style="width: 30%">
          <a-input v-model="detailParams.arriveman" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="到场时间" prop="arrivetime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.arrivetime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="确警时长" prop="timerange" style="width: 30%">
          <a-input v-model="detailParams.timerange" addon-after="分钟" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
          <a-select v-model="detailParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="true">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
          <a-select v-model="detailParams.reports" placeholder="请选择" style="width: 200px;" :disabled="true">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
          <a-input v-model="detailParams.repairorder" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="情况描述" prop="description" style="width: 30%">
          <a-textarea v-model="detailParams.description" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
          <a-textarea v-model="detailParams.measure" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="记录人" prop="recordman" style="width: 30%">
          <a-input v-model="detailParams.recordman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="followreturn" style="width: 30%">
          <a-textarea v-model="detailParams.followreturn" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="确认人" prop="confirmman" style="width: 30%">
          <a-input v-model="detailParams.confirmman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker v-model="detailParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="处理时长" prop="processtime" style="width: 30%">
          <a-input v-model="detailParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录时间" prop="writetime" style="width: 30%">
          <a-date-picker v-model="detailParams.writetime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {exportEventRecordExcel} from "A/jcgn";
import {deleteFirePanel, getFirePanelListByCondition} from "A/wlw";
export default {
  name: "fireRecord",
  mixins: [deptselect,pagination],
  data() {
    return {
      moment,
      detailVisible:false,
      userdepidCascaderSelected: [],
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      detailParams:{},
      queryParams:{
        monitorpointname:'',
        firepanelid:'',
        address:'',
        devicenum:'',
        unittype:'',
        starttime:'',
        location:'',
        content:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '报警/故障内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        {
          title: '部件类型',
          dataIndex: 'unittype',
          key: 'unittype',
          ellipsis: true,
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
        },
        {
          title: '发生时间',
          dataIndex: 'starttime',
          key: 'starttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' },
          width: 150
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      isclickrow:false,
      exportModalVisible:false,
      formDatas:{
        monitorpointnum:'',
        starttime:'',
        endtime:'',
        eventtype:901,
        querytype:1,
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择公司/项目'}],
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "消防报警记录"
    },
    breadcrumb() {
      const pages = [{name:"Ulake Lab",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
      this.formDatas.starttime = start;
      this.formDatas.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow){
              record.starttime=record.starttime?moment(record.starttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              this.detailParams=record
              this.detailVisible=true
            }
          },
        },
      }
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getFirePanelListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let firepanelid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'firepanelid', firepanelid);
      if(type == 'delete') {
        this.deleteConfirm(firepanelid, record)
      }else if(type == 'detail'){
        record.starttime=record.starttime?moment(record.starttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        this.detailParams=record
        this.detailVisible=true
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.firepanelid);
      }).catch(()=>{

      }) ;
    },
    delete(firepanelid) {
      this.showLoading();
      if(firepanelid) {
        let params = {
          firepanelid
        };
        deleteFirePanel(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEventRecord()
      }).catch(()=>{

      }) ;
    },
    exportEventRecord(){
      let params = {
        ...this.queryParams,
        history:true,
      }
      this.showLoading();
      exportEventRecordExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
  },
}
</script>
<style scoped>

</style>