export default {
  data() {
    return {
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },

      currentPage1: 1,
      pagination1: {
        total: 0,
        pageSize: 10,
      },
    }
  },
  methods: {
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    pageChange1(page, size) {
      this.getTableData();
    },
    onShowSizeChange1(page, size) {
      this.pagination1.pageSize = size;
      this.getTableData(true);
    },
  }
}