import store from '@/store'
import http from '@/utils/http'
// import qs from 'qs'

let preApi = '';
if (store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

export function modifyFirePanel(params) {
  return http({
    url: `${preApi}/firepanel/modifyFirePanel`,
    method: "post",
    data: params
  })
}
export function deleteFirePanel(params) {
  return http({
    url: `${preApi}/firepanel/deleteFirePanel`,
    method: "post",
    data: params
  })
}
export function getFirePanelListByCondition(params) {
  return http({
    url: `${preApi}/firepanel/getFirePanelListByCondition`,
    method: "post",
    data: params
  })
}

export function addItemList(params) {
  return http({
    url: `${preApi}/itemlist/addItemList`,
    method: "post",
    data: params
  })
}
export function deleteItemList(params) {
  return http({
    url: `${preApi}/itemlist/deleteItemList`,
    method: "post",
    data: params
  })
}
export function modifyItemList(params) {
  return http({
    url: `${preApi}/itemlist/modifyItemList`,
    method: "post",
    data: params
  })
}
export function getItemListListByCondition(params) {
  return http({
    url: `${preApi}/itemlist/getItemListListByCondition`,
    method: "post",
    data: params
  })
}

// 更新事件历史
export function modifyEventrecord(params) {
  return http({
    url: `${preApi}/event/modifyEventrecord`,
    method: "post",
    data: params
  })
}

// 重置事件历史
export function resetEventRecord(params) {
  return http({
    url: `${preApi}/event/resetEventRecord`,
    method: "post",
    data: params
  })
}

export function getAlarmAndAbnormalNum(params) {
  return http({
    url: `${preApi}/event/getAlarmAndAbnormalNum`,
    method: "post",
    data: params
  })
}

export function getAlarmRecordAnalysis(params) {
  return http({
    url: `${preApi}/event/getAlarmRecordAnalysis`,
    method: "post",
    data: params
  })
}

export function getAbnormalRecordAnalysis(params) {
  return http({
    url: `${preApi}/event/getAbnormalRecordAnalysis`,
    method: "post",
    data: params
  })
}

export function getEventTimeRange(params) {
  return http({
    url: `${preApi}/event/getEventTimeRange`,
    method: "post",
    data: params
  })
}

export function getAlarmEventTimeRange(params) {
  return http({
    url: `${preApi}/event/getAlarmEventTimeRange`,
    method: "post",
    data: params
  })
}

// 7.4.11.	查询事件历史
export function getEventrecordListByCondition(params) {
  return http({
    url: `${preApi}/event/getEventrecordListByCondition`,
    method: "post",
    data: params
  })
}
export function getMajorEventrecordListByCondition(params) {
  return http({
    url: `${preApi}/event/getMajorEventrecordListByCondition`,
    method: "post",
    data: params
  })
}
export function getAbnormalAnalysisReport(params) {
  return http({
    url: `${preApi}/event/getAbnormalAnalysisReport`,
    method: "post",
    data: params
  })
}
export function findAbnormalAnalysisReportGroupLocation(params) {
  return http({
    url: `${preApi}/event/findAbnormalAnalysisReportGroupLocation`,
    method: "post",
    data: params
  })
}
export function exportAbnormalAnalysisReportGroupLocation(params) {
  return http({
    url: `${preApi}/event/exportAbnormalAnalysisReportGroupLocation`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
export function findAbnormalAnalysisMinRangeList(params) {
  return http({
    url: `${preApi}/event/findAbnormalAnalysisMinRangeList`,
    method: "post",
    data: params
  })
}
export function getUnCheckEventrecordListByCondition(params) {
  return http({
    url: `${preApi}/event/getUnCheckEventrecordListByCondition`,
    method: "post",
    data: params
  })
}
export function getMoreEventrecordListByCondition(params) {
  return http({
    url: `${preApi}/event/getMoreEventrecordListByCondition`,
    method: "post",
    data: params
  })
}
//查询事件个数
export function getEventrecordCountByCondition(params) {
  return http({
    url: `${preApi}/event/getEventrecordCountByCondition`,
    method: "post",
    data: params
  })
}
// 7.4.12.	删除事件
export function deleteEventrecord(params) {
  return http({
    url: `${preApi}/event/deleteEventrecord`,
    method: "post",
    data: params
  })
}

//获取事件视频文件地址
export function getVideoRecordingUrl(params) {
  return http({
    url: `${preApi}/event/getVideoRecordingUrl`,
    method: "post",
    data: params
  })
}
export function getLiftVideoRecordingUrl(params) {
  return http({
    url: `${preApi}/event/getLiftVideoRecordingUrl`,
    method: "post",
    data: params
  })
}

//事件查阅
export function addCheckBack(params) {
  return http({
    url: `${preApi}/event/addCheckBack`,
    method: "post",
    data: params
  })
}
export function deleteCheckBack(params) {
  return http({
    url: `${preApi}/event/deleteCheckBack`,
    method: "post",
    data: params
  })
}
export function modifyCheckBack(params) {
  return http({
    url: `${preApi}/event/modifyCheckBack`,
    method: "post",
    data: params
  })
}
export function getCheckBackListByCondition(params) {
  return http({
    url: `${preApi}/event/getCheckBackListByCondition`,
    method: "post",
    data: params
  })
}

export function findCheckBackStatisticsListByCondition(params) {
  return http({
    url: `${preApi}/event/findCheckBackStatisticsListByCondition`,
    method: "post",
    data: params
  })
}


// 7.4.13.	添加事件代码条目
export function addEvent(params) {
  return http({
    url: `${preApi}/event/addEvent`,
    method: "post",
    data: params
  })
}
// 7.4.14.	修改事件代码条目
export function modifyEvent(params) {
  return http({
    url: `${preApi}/event/modifyEvent`,
    method: "post",
    data: params
  })
}
// 7.4.15.	查询事件代码条目列表
export function getEventListByCondition(params) {
  return http({
    url: `${preApi}/event/getEventListByCondition`,
    method: "post",
    data: params
  })
}

//删除测试事件列表
export function deleteNullEventrecord(params) {
  return http({
    url: `${preApi}/event/deleteNullEventrecord`,
    method: "post",
    data: params
  })
}

// 7.4.16.	查询事件数量列表
export function getEventListCountByCondition(params) {
  return http({
    url: `${preApi}/event/getEventListCountByCondition`,
    method: "post",
    data: params
  })
}

// 7.4.17.	删除事件代码条目
export function deleteEvent(params) {
  return http({
    url: `${preApi}/event/deleteEvent`,
    method: "post",
    data: params
  })
}
// 7.4.24.	查询运行状态(追溯)
export function getDevicestatusListByCondition(params) {
  return http({
    url: `${preApi}/device/getDevicestatusListByCondition`,
    method: "post",
    data: params
  })
}
// 7.5.1.	物联网大数据主视图
export function getIotMaindata(params) {
  return http({
    url: `${preApi}/bigdata/getIotMaindata`,
    method: "post",
    data: params
  })
}
// 7.5.2.	物联网大数据地图视图
export function getIotMapdata(params) {
  return http({
    url: `${preApi}/bigdata/getIotMapdata`,
    method: "post",
    data: params
  })
}
// 7.5.3.	物联网大数据事件统计
export function getIotDateevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotDateevent`,
    method: "post",
    data: params
  })
}
// 7.5.4.	物联网大数据故障类型统计
export function getIotTypeevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotTypeevent`,
    method: "post",
    data: params
  })
}

export function getAllIotTypeevent(params) {
  return http({
    url: `${preApi}/bigdata/getAllIotTypeevent`,
    method: "post",
    data: params
  })
}
// 7.5.5.	物联网大数据电梯品牌统计
export function getIotBranddata(params) {
  return http({
    url: `${preApi}/bigdata/getIotBranddata`,
    method: "post",
    data: params
  })
}
// 7.5.6.	物联网大数据月度趋势数据统计
export function getIotMonthevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotMonthevent`,
    method: "post",
    data: params
  })
}
export function getAllIotMonthevent(params) {
  return http({
    url: `${preApi}/bigdata/getAllIotMonthevent`,
    method: "post",
    data: params
  })
}
// 7.5.7.	物联网大数据场所困人统计
export function getIotPlaceevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotPlaceevent`,
    method: "post",
    data: params
  })
}

export function getAllUserdepLists(params) {
  return http({
    url: `${preApi}/userdep/getAllUserdepLists`,
    method: "post",
    data: params
  })
}

export function getAllLiftinfoLists(params) {
  return http({
    url: `${preApi}/lift/getAllLiftinfoLists`,
    method: "post",
    data: params
  })
}

export function getAreaByMonitor(params) {
  return http({
    url: `${preApi}/area/getAreaByMonitor`,
    method: "post",
    data: params
  })
}

// 添加事件历史
export function addLiftEventrecord(params) {
  return http({
    url: `${preApi}/event/addLiftEventrecord`,
    method: "post",
    data: params
  })
}
// 更新事件历史
export function modifyLiftEventrecord(params) {
  return http({
    url: `${preApi}/event/modifyLiftEventrecord`,
    method: "post",
    data: params
  })
}

export function getLiftAlarmAndAbnormalNum(params) {
  return http({
    url: `${preApi}/event/getLiftAlarmAndAbnormalNum`,
    method: "post",
    data: params
  })
}

export function getLiftAlarmRecordAnalysis(params) {
  return http({
    url: `${preApi}/event/getLiftAlarmRecordAnalysis`,
    method: "post",
    data: params
  })
}

export function getLiftAbnormalRecordAnalysis(params) {
  return http({
    url: `${preApi}/event/getLiftAbnormalRecordAnalysis`,
    method: "post",
    data: params
  })
}

export function getLiftEventTimeRange(params) {
  return http({
    url: `${preApi}/event/getLiftEventTimeRange`,
    method: "post",
    data: params
  })
}

export function getLiftAlarmEventTimeRange(params) {
  return http({
    url: `${preApi}/event/getLiftAlarmEventTimeRange`,
    method: "post",
    data: params
  })
}

// 7.4.11.	查询事件历史
export function getLiftEventrecordListByCondition(params) {
  return http({
    url: `${preApi}/event/getLiftEventrecordListByCondition`,
    method: "post",
    data: params
  })
}
//查询事件个数
export function getLiftEventrecordCountByCondition(params) {
  return http({
    url: `${preApi}/event/getEventrecordCountByCondition`,
    method: "post",
    data: params
  })
}
//删除事件
export function deleteLiftEventrecord(params) {
  return http({
    url: `${preApi}/event/deleteLiftEventrecord`,
    method: "post",
    data: params
  })
}

//添加事件代码条目
export function addLiftEvent(params) {
  return http({
    url: `${preApi}/event/addLiftEvent`,
    method: "post",
    data: params
  })
}
//修改事件代码条目
export function modifyLiftEvent(params) {
  return http({
    url: `${preApi}/event/modifyLiftEvent`,
    method: "post",
    data: params
  })
}
//查询事件代码条目列表
export function getLiftEventListByCondition(params) {
  return http({
    url: `${preApi}/event/getLiftEventListByCondition`,
    method: "post",
    data: params
  })
}

//删除测试事件列表
export function deleteNullLiftEventrecord(params) {
  return http({
    url: `${preApi}/event/deleteLiftNullEventrecord`,
    method: "post",
    data: params
  })
}

//查询事件数量列表
export function getLiftEventListCountByCondition(params) {
  return http({
    url: `${preApi}/event/getLiftEventListCountByCondition`,
    method: "post",
    data: params
  })
}

//删除事件代码条目
export function deleteLiftEvent(params) {
  return http({
    url: `${preApi}/event/deleteLiftEvent`,
    method: "post",
    data: params
  })
}